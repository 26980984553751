<template>
  <div id="slideshow" :class="{reveal: slideshowMode == true}">
      <div id="ss_carousel_container">
          <carousel id="ss_carousel" v-if="photos_sizes != null && album.photos.length <= SS_LIMIT" :perPage="1" :adjustableHeight="true" :value="slideshow_index" @transition-end="pageChange" @page-change="updateCurrentIndex" :centerMode="true" :scrollPerPage="false" :paginationEnabled="false" :adjustableHeightEasing="'ease'" :autoplay="false" :loop="true">
              <template v-for="(photo, index) in album.photos">
                  <slide :key="photo.id">
                    <div class="ss_img_container">
                        <img :id="`img_${index}`" :class="{horizontal_img: photo.width >= photo.height, vertical_img: photo.width < photo.height}" :src="getPhotoUrl(index)" @load="imageLoaded(index)"/>
                    </div>
                  </slide>
              </template>
          </carousel>

          <carousel id="ss_carousel" v-if="photos_sizes != null && album.photos.length > SS_LIMIT" :perPage="1" :adjustableHeight="true" :value="slideshow_index" @transition-end="pageChange" @page-change="updateCurrentIndex" :centerMode="true" :scrollPerPage="false" :paginationEnabled="false" :adjustableHeightEasing="'ease'" :autoplay="false" :loop="true">
              <template v-for="(index, index_val) in slideshow_photos">
                  <slide :key="index_val">
                    <div class="ss_img_container">
                        <img :id="`img_${index}`" :class="{horizontal_img: album.photos[index].width >= album.photos[index].height, vertical_img: album.photos[index].width < album.photos[index].height}" :src="getPhotoUrl(index)" @load="imageLoaded(index)"/>
                    </div>
                  </slide>
              </template>
          </carousel>

          <!-- <carousel id="ss_carousel" :class="{noShow: carousel_translated != true}" v-if="photos_sizes != null" @translated="carouselTranslated" :items="1" :center="true" :lazyLoad="true" :lazyLoadEager="10" :loop="true" :dots="true" :nav="false" :fluidSpeed="350" :smartSpeed="350"  @changed="pageChange">
              <template v-for="(photo, index) in album.photos">
                    <div class="ss_img_container" :key="photo.id">
                        <img :id="`img_${index}`" class="noShow owl-lazy" :class="{horizontal_img: photo.width >= photo.height, vertical_img: photo.width < photo.height}" :data-src="getPhotoUrl(index)" @load="imageLoaded(index)"/>
                    </div>
              </template>
          </carousel> -->
      </div>
      <div id="slideshow_actions_container">
          <div id="slideshow_actions" :class="{light_mode_color: album != null && (album.category == 'Proposals' || album.category == 'Weddings')}">
              <md-button @click="exitSlideshow" class="md-icon-button btn" :class="{'md-mini': isMobile}">
                <md-icon>close</md-icon>
                <md-tooltip v-if="!isMobile" md-direction="bottom">Exit fullscreen</md-tooltip>
              </md-button>

              <md-button @click="incrementPage(-1)" class="md-icon-button prev_btn btn" :class="{'md-mini': isMobile}">
                <md-icon>arrow_back_ios_new</md-icon>
                <md-tooltip v-if="!isMobile" md-direction="bottom">Previous photo</md-tooltip>
              </md-button>
              <!-- <b-icon class="return_to_btn btn" v-b-tooltip.hover title="Exit fullscreen" @click="exitSlideshow" icon="x" :scale="icon_scale + 0.5" aria-hidden="true"></b-icon> -->
              <!-- <b-icon class="prev_btn btn" v-b-tooltip.hover title="Previous photo" @click="incrementPage(-1)" icon="chevron-left" :scale="icon_scale" aria-hidden="true"></b-icon> -->
              <div id="current_text_container">{{(real_index + 1) + "/" + album.photos.length}}</div>
              <md-button @click="incrementPage(1)" class="md-icon-button next_btn btn ">
                <md-icon>arrow_forward_ios</md-icon>
                <md-tooltip v-if="!isMobile" md-direction="bottom">Next photo</md-tooltip>
              </md-button>
              <!-- <b-icon class="next_btn btn" v-b-tooltip.hover title="Next photo" @click="incrementPage(1)" icon="chevron-right" :scale="icon_scale" aria-hidden="true"></b-icon> -->
              <!-- <b-icon class="download_btn btn" v-b-tooltip.hover title="Download" @click="downloadImg" icon="download" :scale="icon_scale" aria-hidden="true"></b-icon> -->

              <md-button @click="downloadImg" class="md-icon-button download_btn btn " :class="{'md-mini': isMobile}">
                <b-icon icon="arrow-down" :scale="icon_scale" aria-hidden="true"></b-icon>
                <md-tooltip v-if="!isMobile" md-direction="bottom">Download</md-tooltip>
              </md-button>
              
              <div id="ss_likes_container" ref="ss_like_btn">
                <md-button class="md-icon-button ss_like_btn" :class="{'md-mini': isMobile}">
                    <b-icon icon="heart" :class="{noShow: (liked[album.photos[real_index].id] != null || likesList.add[album.photos[real_index].id] != null) && likesList.remove[album.photos[real_index].id] == null}" style="color: white; width: 100%; margin: auto;" :scale="icon_scale" aria-hidden="true"></b-icon>
                    <b-icon icon="heart-fill" :class="{noShow: (likesList.add[album.photos[real_index].id] == null && liked[album.photos[real_index].id] == null) || likesList.remove[album.photos[real_index].id] != null}" style="color: #ff2692; width: 100%; margin: auto; margin-left: -100%;" :scale="icon_scale" aria-hidden="true"></b-icon>
                    <!-- <md-icon style="width: 100%; margin: auto;" :class="{noShow: (liked[album.photos[real_index].id] != null || likesList.add[album.photos[real_index].id] != null) && likesList.remove[album.photos[real_index].id] == null}">favorite_border</md-icon>
                    <md-icon style="color: #ff2692 !important; width: 100%; margin: auto; margin-left: -100%;" :class="{noShow: (likesList.add[album.photos[real_index].id] == null && liked[album.photos[real_index].id] == null) || likesList.remove[album.photos[real_index].id] != null}">favorite</md-icon> -->
                    <md-tooltip v-if="!isMobile" md-direction="bottom">Like</md-tooltip>
                </md-button>

                <!-- <div class="ss_like_btn" ref="ss_like_btn" @click="likeBtnClick">
                    <b-icon icon="heart" :class="{noShow: (liked[album.photos[real_index].id] != null || likesList.add[album.photos[real_index].id] != null) && likesList.remove[album.photos[real_index].id] == null}" style="width: 100%; margin: auto;" :scale="icon_scale" aria-hidden="true"></b-icon>
                    <b-icon icon="heart-fill" :class="{noShow: (likesList.add[album.photos[real_index].id] == null && liked[album.photos[real_index].id] == null) || likesList.remove[album.photos[real_index].id] != null}" style="color: #ff2692; width: 100%; margin: auto; margin-left: -100%;" :scale="icon_scale" aria-hidden="true"></b-icon>
                </div> -->
                <div class="like_num">{{album.photos[real_index].likes}}</div>
              </div> 
          </div>
      </div>

      <transition name="fade">
          <div id="ldr" class="lds-roller" :class="{noShow: real_index in loadedPhotos}"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </transition>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
// import carousel from 'vue-owl-carousel';

export default {
  name: 'Slideshow',
  components: {
    Carousel,
    Slide
    // carousel
  },

  props: {
    isMobile: Boolean,
    album: Object,
    slideshowMode: Boolean,
    likesList: Object,
    liked: Object
  },

  data() {
    return {
        slideshow_index: 40,
        current_index: 40,
        root_index: 0,
        slideshow_photos: [],
        icon_scale: 1.2,
        photos_sizes: null,
        carousel_translated: false,
        neighborLimit: 20,
        SS_LIMIT: 40,
        loadedPhotos: {}
    }
  },
  
  computed: {
    real_index: function () {
      if (this.album.photos.length <= this.SS_LIMIT || this.slideshow_photos.length == 0) {
          return this.current_index
      } else {
          return this.slideshow_photos[this.current_index]
      }
    }
  },

  created() {
    if (this.album.photos.length > this.SS_LIMIT) {
        var photos_indexes = []
        var right_indexes = []
        var left_indexes = []
        if (this.root_index + this.neighborLimit >= this.album.photos.length) {
            right_indexes = [...Array(this.album.photos.length - this.root_index).keys()].map(i => i + this.root_index);
            let right_indexes2 = [...Array(this.neighborLimit - (this.album.photos.length - this.root_index)).keys()]
            right_indexes = right_indexes.concat(right_indexes2);
        } else {
            right_indexes = [...Array(this.neighborLimit).keys()].map(i => i + this.root_index);
        }

        if (this.root_index - this.neighborLimit < 0) {
            left_indexes = [...Array(this.root_index).keys()]
            let left_indexes2 = [...Array(this.neighborLimit - this.root_index).keys()].map(i => i + (this.album.photos.length - this.neighborLimit - this.root_index));
            left_indexes = left_indexes2.concat(left_indexes);
        } else {
            left_indexes = [...Array(this.neighborLimit).keys()].map(i => i + (this.root_index - this.neighborLimit));
        }


        photos_indexes = left_indexes.concat(right_indexes);
        this.slideshow_photos = photos_indexes;
        this.slideshow_index = this.neighborLimit;
        this.current_index = this.neighborLimit;
    }
  },
  
  mounted() {
    document.getElementById('slideshow').classList.add("noDisplaySS");
    document.getElementById('slideshow_actions_container').classList.add("noDisplaySS");
    // document.getElementsByClassName('owl-stage')[0].classList.add("noDisplay");
    // document.getElementById('ss_carousel').classList.add("noShow");
    
  },
  
  methods: {
    login: function (event) {
      // `this` inside methods points to the Vue instance
      alert('Hello ' + this.name + '!')
      // `event` is the native DOM event
      if (event) {
        alert(event.target.tagName)
      }
    },

    resetSlideshow() {
        if (this.album.photos.length > this.SS_LIMIT) {
            this.slideshow_index = this.neighborLimit;
            this.current_index = this.neighborLimit;
        }
    },

    exitSlideshow() {
        this.carousel_translated = false;
        this.$emit('exitSlideshow');
    },

    updateCurrentIndex(newIndex) {
        if (!isNaN(newIndex)) {
            this.slideshow_index = newIndex;
            this.current_index = newIndex;  
        }
    },

    photos_preprocessed(val) {
        this.photos_sizes = val;
        setTimeout(function() {
            // document.getElementsByClassName('owl-stage')[0].classList.add("noDisplay");
            // document.getElementById('ss_carousel').classList.add("noShow");
        }, 1000)
    },

    likeBtnClick() {
      let svgs = this.$refs.ss_like_btn.getElementsByTagName('svg');
      var like_obj = {id: this.album.photos[this.real_index].id, index: this.real_index};
      if (svgs[1].classList.contains("noShow")) {
        like_obj.action = "add";
        svgs[1].classList.remove("noShow");
        svgs[0].classList.add("noShow");
        this.album.photos[this.real_index].likes += 1;
      } else {
        like_obj.action = "remove";
        svgs[0].classList.remove("noShow");
        svgs[1].classList.add("noShow");
        if (this.album.photos[this.real_index].likes > 0) {
          this.album.photos[this.real_index].likes -= 1;
        }
      }
      this.$emit('likesUpdate', like_obj);
    },

    carouselTranslated() {
        // document.getElementById('ss_carousel').classList.remove("noShow");
        this.carousel_translated = true
    },

    downloadImg() {
        if (!this.isMobile) {
            window.open(this.album.photos[this.real_index].url + '=d', '_blank');
        } else {
            window.open(this.album.photos[this.real_index].url + '=d');
        }
        
    },

    pageChange(custom_index) {
        var theIndex = this.real_index;
        if (custom_index) {
            theIndex = this.slideshow_photos[custom_index];
        }

        if (!(theIndex in this.loadedPhotos)) {
            document.getElementById(`ldr`).classList.remove("noShow");
        } else {
            document.getElementById(`ldr`).classList.add("noShow");
        }

        // this.slideshow_index = theIndex;
        var photo_url = null;

        var photo = this.album.photos[theIndex];
        if (photo.width > photo.height) {
            let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if (this.isMobile) {
                width *= 3;
            }
            photo_url = `${photo.url}=w${width}`
        } else {
            let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            if (this.isMobile) {
                height *= 3;
            }
            photo_url = `${photo.url}=h${height}`
        }

        var elem = document.getElementById(`img_${theIndex}`);

        if (elem && elem.src != photo_url) {
            var image = new Image();
            image.addEventListener('load', function() {
                document.getElementById(`img_${theIndex}`).src = image.src;
                this.loadedPhotos[theIndex] = true;
                let loader = document.getElementById(`ldr`)
                if (loader) {
                    loader.classList.add("noShow");
                }
            }.bind(this));
            image.src = photo_url;
        }
        

        let svgs = this.$refs.ss_like_btn.getElementsByTagName('svg');
        if (this.liked[this.album.photos[this.real_index].id] != null || this.likesList.add[this.album.photos[this.real_index].id] != null) {
            svgs[1].classList.remove("noShow");
            svgs[0].classList.add("noShow");
        } else {
            svgs[0].classList.remove("noShow");
            svgs[1].classList.add("noShow");
        }
    },

    preloadImgs(val) {
        var preload_ids = [val];
        if (val >= 5 && val < this.album.photos.length - 5) {
            for (let x=1; x<=5; x++) {
                preload_ids.push(val - x);
            }

            for (let x=1; x<=10; x++) {
                preload_ids.push(val + x);
            }
        } else if (val < 5) {
            for (let x=0; x<val; x++) {
                preload_ids.push(x);
            }

            for (let x=1; x<=10; x++) {
                preload_ids.push(val + x);
            }
        } else if (this.album.photos.length - 5 <= val) {
            for (let x=val+1; x<this.album.photos.length; x++) {
                preload_ids.push(x);
            }

            for (let x=1; x<=5; x++) {
                preload_ids.push(val - x);
            }
        }

        let elem = document.getElementById(`img_${val}`);
        let isLoaded = elem.getAttribute('src');
        if (isLoaded == null || isLoaded == "") {
         elem.src = elem.getAttribute("data-src");
         elem.onload = function() {
             this.triggerPreLoads(preload_ids);
         }.bind(this);
        } else {
            this.triggerPreLoads(preload_ids);
        }

        
    },

    triggerPreLoads(preload_ids) {
        for (let preload of preload_ids) {
            let elem = document.getElementById(`img_${preload}`);
            let isLoaded = elem.getAttribute('src');
            if (isLoaded != null && isLoaded != "") {
                continue;
            }

            elem.src = elem.getAttribute("data-src");
        }
    },

    imageLoaded(index) {
        document.getElementById(`img_${index}`).classList.remove("noShow");
    },

    incrementPage(val) {
        if (this.slideshow_index + val > 0 && this.current_index + val > 0 && this.current_index + val < this.slideshow_photos.length && this.slideshow_index + val < this.slideshow_photos.length) {
            this.slideshow_index += val;
            this.current_index += val;
        }
        
    },

    firstSlideshowClick() {
        if (this.slideshow_photos.length > 0) {
            let inner_carousel = document.getElementById('ss_carousel').getElementsByClassName("VueCarousel-inner")[0];
            inner_carousel.style.transition = "0s all";
            inner_carousel.style.transform = `translate(-${Math.ceil(inner_carousel.clientWidth * this.neighborLimit)}px, 0px)`;
            setTimeout(function() {
                document.getElementById('ss_carousel').getElementsByClassName("VueCarousel-inner")[0].style.transition = "transform 0.5s ease 0s, height 0.5s ease 0s";
            }, 10);
        }
    },

    setPage(val) {
        console.log("SS: " + this.slideshow_index + ", CI: " + this.current_index);
        if (this.album.photos.length <= this.SS_LIMIT) {
            this.slideshow_index = val;
            this.current_index = val;
        } else {
            this.root_index = val;
            var photos_indexes = []
            var right_indexes = []
            var left_indexes = []
            if (this.root_index + this.neighborLimit >= this.album.photos.length) {
                right_indexes = [...Array(this.album.photos.length - this.root_index).keys()].map(i => i + this.root_index);
                let right_indexes2 = [...Array(this.neighborLimit - (this.album.photos.length - this.root_index)).keys()]
                right_indexes = right_indexes.concat(right_indexes2);
            } else {
                right_indexes = [...Array(this.neighborLimit).keys()].map(i => i + this.root_index);
            }

            if (this.root_index - this.neighborLimit < 0) {
                left_indexes = [...Array(this.root_index).keys()]
                let left_indexes2 = [...Array(this.neighborLimit - this.root_index).keys()].map(i => i + (this.album.photos.length - this.neighborLimit - this.root_index));
                left_indexes = left_indexes2.concat(left_indexes);
            } else {
                left_indexes = [...Array(this.neighborLimit).keys()].map(i => i + (this.root_index - this.neighborLimit));
            }


            photos_indexes = left_indexes.concat(right_indexes);
            this.slideshow_photos = photos_indexes;
            this.slideshow_index = this.neighborLimit;
            this.current_index = this.neighborLimit;
        }
        this.pageChange(this.neighborLimit);
    },

    getPhotoUrl(index) {
        var photo = this.album.photos[index];
        var photo_size = this.photos_sizes[index];
        let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        return `${photo.url}=w${Math.floor(parseInt(photo_size * ((!this.isMobile) ? width : width * 3)))}`
        // if (photo.width > photo.height) {
        //     let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        //     if (this.isMobile) {
        //         width *= 3;
        //     }
        //     return `${photo.url}=w${width}`
        // } else {
        //     let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        //     if (this.isMobile) {
        //         height *= 3;
        //     }
        //     return `${photo.url}=h${height}`
        // }

    },
  }
}
</script>

<style scoped>

@keyframes scale {
    0% {transform: scale(1.0);}
    50% {transform: scale(1.3);}
    100% {transform: scale(1.0);}
}

/* Firefox < 16 */
@-moz-keyframes scale {
    0% {transform: scale(1.0);}
    50% {transform: scale(1.3);}
    100% {transform: scale(1.0);}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes scale {
    0% {transform: scale(1.0);}
    50% {transform: scale(1.3);}
    100% {transform: scale(1.0);}
}

/* Internet Explorer */
@-ms-keyframes scale {
    0% {transform: scale(1.0);}
    50% {transform: scale(1.3);}
    100% {transform: scale(1.0);}
}

/* Opera < 12.1 */
@-o-keyframes scale {
    0% {transform: scale(1.0);}
    50% {transform: scale(1.3);}
    100% {transform: scale(1.0);}
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#slideshow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    display: -webkit-box;
    height: 100vh;
    opacity: 0;
    transition: 0.4s ease;
    z-index: 100;
    backdrop-filter: brightness(10%);
}

.reveal {
    opacity: 1 !important;
}

#ss_carousel_container {
    width: 100%;
    height: auto;
    display: flex;
    max-height: 95vh;
}

#ss_carousel {
    width: 75vw;
    height: auto;
    margin: auto;
    transition: 1s opacity ease;
}

#ss_carousel .VueCarousel-slide {
    width: 75vw;
}

#ss_carousel .VueCarousel-slide {
    width: 75vw;
    height: inherit;
}

.ss_img_container {
    padding: 2rem;
    width: inherit;
    height: inherit;
    display: flex;
    margin: 0 !important;
    position: relative;
}

.ss_img_container img {
    width: auto;
    height: auto;
    /* max-height: 100% !important; */
    max-width: 90% !important;
    margin: auto;
    transition: all 1s, opacity 1s linear 1s;
    /* position: absolute; */
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
    background: white;
}

.horizontal_img {
    width: 90% !important;
    height: auto !important;
}

.vertical_img {
    height: inherit !important;
    max-height: 90% !important;
    width: auto !important;
}

#slideshow_actions {
    display: flex;
    margin: auto;
    margin-top: 0;
    color: white;
}

.light_mode_color {
    color: white !important;
}

#slideshow_actions .btn {
    /* padding: 1rem; */
    margin: auto;
    border-radius: 50%;
    width: 8rem;
    height: 2rem;
    transition: 0.3s ease;
    cursor: pointer;
    color: inherit;
    /* filter: drop-shadow( 0 0 0.4rem rgba(0, 0, 0, .3)); */
    width: 2vw !important;
    height: 2vw !important;
    margin: 0 3rem 0 3rem !important;
    padding: 0 !important;
}

#slideshow_actions_container {
    width: 100%;
    height: auto;
    display: -webkit-inline-box;
    position: fixed;
    bottom: 0;
    padding-bottom: 1rem;
    background: transparent;
}

.noShow {
  opacity: 0;
}

#current_text_container {
    cursor: default;
    display: block;
    text-align: center;
    vertical-align: center;
    margin: auto;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none;
    margin: auto;
    vertical-align: middle;
    text-align: center;
    margin: auto;
    color: inherit;
    width: 5vw;
    font-family: 'roboto';
    font-weight: 700;
}


.ss_like_btn {
    display: flex;
    transition: 0.3s ease;
    cursor: pointer;
    width: 2vw;
    height: 2vw;
    /* padding: 1rem; */
    margin: auto;
    color: inherit;
    margin-left: 2rem;
    /* filter: drop-shadow( 0 0 0.4rem rgba(0, 0, 0, .3)); */
}

.like_num {
    font-size: 0.8rem;
    margin: auto;
    padding-bottom: 0.3rem;
    width: 2vw;
    cursor: default;
    transition: 0.3s ease;
    font-family: 'roboto';
    font-weight: 700;
}

.ss_like_btn svg {
  transition: 0.3s ease !important;
}

#ss_likes_container {
    display: inherit;
}



@media (min-width:481px) {
    /* #ss_likes_container:hover .ss_like_btn {
        transform: scale(1.3);
    } */

    #ss_likes_container:hover .like_num {
        transform: scale(1.3);
        transform: translateX(37%);
    }

    /* .btn:hover {
        transform: scale(1.3);
    } */

    .ss_img_container img {
        box-shadow: 0 0 0.5rem rgb(0 0 0 / 93%);
    }
}

@media (max-width:481px) {
    .noDisplay {
        display: none !important;
    }

    .noDisplaySS {
        display: none !important;
        position: absolute !important;
        right: -120vw !important;
    }

    #slideshow {
        display: flex !important;
    }

    #ss_carousel {
        width: 100vw !important;
        height: 80vh !important;
        margin: auto;
        margin-top: 1rem !important;
        transition: 1s opacity ease;
    }

    .ss_img_container {
        padding: 0.5rem !important;
    }

    #slideshow_actions .btn {
        width: 10vw !important;
        height: 10vw !important;
        padding: 0 !important;
        border-radius: 0 !important;
        margin: 0 0.5rem 0 0.5rem !important;
    }

    #ss_carousel_container {
        height: 80vh !important;
        position: relative !important;
    }

    #slideshow_actions_container {
        width: 100vw !important;
        height: -webkit-fit-content !important;
        display: block !important;
        position: fixed !important;
        bottom: 1.5rem !important;
        left: 0 !important;
    }

    #slideshow_actions {
        display: flex;
        margin: auto;
        margin-top: 0;
        color: white;
        width: 80% !important;
        height: inherit !important;
    }

    #current_text_container {
        font-size: 0.75rem !important;
        width: inherit !important;
    }

    .ss_like_btn {
        display: flex;
        transition: 0.3s ease;
        cursor: pointer;
        width: 10vw !important;
        height: 10vw !important;
        /* padding: 1rem; */
        margin: auto;
        color: inherit;
        padding-left: 0rem !important;
        padding-right: 0rem !important;
        /* filter: drop-shadow( 0 0 0.4rem rgba(0, 0, 0, .3)); */
    }

    .like_num {
        width: 6vw !important;
        padding-left: 0.1rem !important;
        font-size: 0.75rem !important;
    }

    .ss_like_btn svg {
        width: auto !important;
    }

    #ss_likes_container:hover .ss_like_btn {
        animation: scale 0.3s;
    }

    #ss_likes_container:hover .like_num {
        animation: scale 0.3s;
    }

    .btn:hover {
        animation: scale 0.3s;
    }

    .ss_img_container img {
        box-shadow: unset !important;
    }

    #slideshow.noDisplay {
        display: none !important;
    }

    #slideshow.noDisplaySS {
        display: none !important;
        position: absolute !important;
        right: -120vw !important;
    }

    .vertical_img {
        height: 90% !important;
        max-height: unset !important;
        width: auto !important;
    }

    .vertical_loader {
        right: 31% !important;
        bottom: 7% !important;
    }

    #ss_carousel .VueCarousel-slide {
        width: 100% !important;
        height: inherit;
    }

    .lds-roller {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        position: absolute;
        top: 0% !important;
        right: 1% !important;
        transform: scale(0.3) !important;
        transition: opacity 0.5s ease;
    }

    .vertical_loader {
        right: 5% !important;
        bottom: 5% !important;
    }
        
}




.vertical_loader {
    right: 31.5% !important;
    bottom: 7% !important;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  position: fixed;
    bottom: 0;
    right: 1%;
    transform: scale(0.4);
    transition: opacity 0.5s ease;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>

<style>

#ss_carousel .VueCarousel-wrapper {
    width: 100%;
    height: calc(75vw * 0.66);
    position: relative;
    overflow: hidden;
}

.owl-stage {
    height: calc(75vw * 0.66) !important;
}

.owl-item {
    height: inherit !important;
}

.owl-dots {
    display: none;
}

#slideshow_actions .md-icon {
    color: white !important;
}

#slideshow_actions .md-button:focus {
    box-shadow: none !important;
}

@media (max-width:481px) {
    #ss_carousel .VueCarousel-wrapper {
        width: 100vw !important;
        height: 80vh !important;
        position: relative;
        overflow: hidden;
    }
}

</style>
